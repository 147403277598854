import React, { Component } from 'react';
import Projectfull from "../../components/Projectfull";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import vertigofullImg from "../../images/projectsfull/vertigofull.jpg";
import {GatsbySeo} from "gatsby-plugin-next-seo";
import {Helmet} from "react-helmet";

class Projects extends Component {
  render() {
    return (
      <div className="scroll-content">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Vertigo menswear</title>
        </Helmet>
        <GatsbySeo noindex={true} />
        <div className="page project">
          <Header />
          <Projectfull
              projectfullname="Vertigo menswear"
              projectfulldescription="For this menswear store I designed a website with a sleek and modern look. This design was especially different from other websites I’ve worked on before because there was full creative freedom for this project."
              projectclient="Vertigo"
              projectrole="Web Design & Front-end Development"
              projectdate="2017 - 2018"
              projectfullimg={<div className="project--img--inner"><img data-src={vertigofullImg} className="project--img lazyload" alt="Vertigo menswear" /></div>}
              projectnextlink="/projects/avc"
          />
          <Footer />
        </div>
      </div>
    );
  }
}

export default Projects;